import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store';
import store from './store/index.js';
import dataV from '@jiaminghi/data-view';

import axios from 'axios';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

// import "ant-design-vue/dist/antd.css";

//引入echart
//4.x 引用方式
import echarts from 'echarts';
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

// import ProLayout from '@ant-design-vue/pro-layout'
// import  PageHeaderWrapper  from '@/layouts/HideBreadCrumb'
// Vue.component('pro-layout', ProLayout)
// Vue.component('page-container', PageHeaderWrapper)
// Vue.component('page-header-wrapper', PageHeaderWrapper)

// import { Button, Form, Input } from 'antd'
// Vue.use(Button).use(Form).use(Input)
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
