import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    startTimes: '', //查询开始时间
    endTimes: '', //查询结束时间
    searchType: 0, // 0:当天  1：当周  2：当月 3：自定义
    nowDate: '', // 当前日期
    nowTime: '', // 当前时间
    nowWeek: '', // 当前星期
	shift: 0, //0:不指定班次, 1:班次1, 2:班次2, 3:班次3, 4:班次4
  },
  mutations: {
    setStartTimes(state, newStartTimes) {
      state.startTimes = newStartTimes;
    },
    setEndTimes(state, newEndTimes) {
      state.endTimes = newEndTimes;
    },
    setSearchType(state, otypes) {
      state.searchType = otypes;
    },
    setNowDate(state, value) {
      state.nowDate = value;
    },
    setNowTime(state, value) {
      state.nowTime = value;
    },
    setNowWeek(state, value) {
      state.nowWeek = value;
    },
	setShift(state, newShift) {
	  state.shift = newShift;
	},
  },
  actions: {},
  modules: {},
});
export default store;
